import { FormType } from '@/constant/form';
import { DateTime } from '@/constant/tableConfig';
import {
  COUNT_TYPE, COUNT_STATUS, OPERATION_TYPE, TASKGENERATE_TYPE, IS_DIFF, IS_LOCK_STOCK_AMOUNT_EN,
} from './constant';

function filterLabel(val, arr) {
  const filterArr = arr.find((item) => item.value === val);
  return filterArr ? filterArr.label : '';
}
export const TABLECOLUMN = [{
  type: 'selection',
  width: 50,
}, {
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '盘点单号',
  prop: 'countOrderNo',
  minWidth: 180,
}, {
  label: '盘点类型',
  prop: 'countType',
  minWidth: 100,
  formatter: (row) => filterLabel(row.countType, COUNT_TYPE),
}, {
  label: '盘点方式',
  prop: 'operationType',
  minWidth: 120,
  formatter: (row) => filterLabel(row.operationType, OPERATION_TYPE),
}, {
  label: '任务生成方式',
  prop: 'taskGenerateType',
  minWidth: 120,
  formatter: (row) => filterLabel(row.taskGenerateType, TASKGENERATE_TYPE),
}, {
  label: '是否锁库盘点',
  prop: 'isLockStockAmount',
  minWidth: 120,
  formatter: (row) => (row.isLockStockAmount
    ? IS_LOCK_STOCK_AMOUNT_EN.LOCK : IS_LOCK_STOCK_AMOUNT_EN.NO_LOCK),
}, {
  label: '状态',
  prop: 'status',
  minWidth: 130,
  formatter: (row) => filterLabel(row.status, COUNT_STATUS),
}, {
  label: '是否有差异',
  prop: 'isDiff',
  minWidth: 130,
  formatter: (row) => filterLabel(row.isDiff, IS_DIFF),
}, {
  label: '总库位数',
  prop: 'totalStorehouseNum',
  minWidth: 130,
}, {
  label: '已盘库位数',
  prop: 'countStorehouseNum',
  minWidth: 130,
}, {
  label: '差异库位数',
  prop: 'diffStorehouseNum',
  minWidth: 130,
}, {
  label: '创建人',
  prop: 'createUserName',
  minWidth: 130,
}, {
  label: '创建时间',
  prop: 'createTime',
  ...DateTime,
}, {
  label: '盘点完成时间',
  prop: 'completeTime',
  ...DateTime,
}, {
  label: '操作',
  prop: 'action',
  fixed: 'right',
  width: 220,
},
];
export const serchFields = [{
  label: '盘点单号',
  prop: 'countOrderNo',
  component: FormType.INPUT,
}, {
  label: '盘点类型',
  prop: 'countType',
  component: FormType.SELECT,
  options: COUNT_TYPE,
}, {
  label: '盘点方式',
  prop: 'operationType',
  component: FormType.SELECT,
  options: OPERATION_TYPE,
}, {
  label: '盘点状态',
  prop: 'status',
  component: FormType.SELECT,
  options: COUNT_STATUS,
}, {
  label: '创建人',
  prop: 'createUserName',
  component: FormType.INPUT,
}, {
  label: '创建时间',
  prop: 'createTime',
  component: FormType.DATE_PICKER,
  componentAttrs: {
    type: 'daterange',
    default: [],
  },
}, {
  label: '盘点完成时间',
  prop: 'completeTime',
  component: FormType.DATE_PICKER,
  componentAttrs: {
    type: 'daterange',
    default: [],
  },
},
];
