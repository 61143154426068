<template>
  <nh-dialog
    v-model="dialogVisible"
    title="新增"
    width="1200px"
    custom-class="custom-dialog_1200 dialog-count-order-add"
    :track="{
      name_zh: `在库管理/盘点管理/弹窗-新增`,
      trackName: $route.path
    }"
    @close="handleClose"
  >
    <nh-dialog-form
      ref="ruleForm"
      :model="form"
      label-width="130px"
      :rules="rules"
    >
      <div style="width: 100%;">
        <div class="title-text-box">
          <nh-title-text title="盘点设置" />
        </div>
        <nh-dialog-form-item
          label="盘点类型："
          :span="8"
          prop="countType"
        >
          <el-select v-model="form.countType" placeholder="请选择">
            <el-option
              v-for="item in ADD_COUNT_TYPE"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </nh-dialog-form-item>
        <nh-dialog-form-item
          label="盘点方式："
          :span="8"
          prop="countOperationType"
        >
          <el-select
            v-model="form.countOperationType"
            placeholder="请选择"
          >
            <el-option
              v-for="item in OPERATION_TYPE"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </nh-dialog-form-item>
        <nh-dialog-form-item
          label="是否盲盘："
          :span="8"
          prop="isBlindCount"
        >
          <el-select v-model="form.isBlindCount" placeholder="请选择">
            <el-option
              v-for="item in IS_SHOW_STOCK_AMOUNT"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </nh-dialog-form-item>
        <nh-dialog-form-item
          label="是否锁库盘点："
          :span="8"
          prop="isLockStockAmount"
        >
          <el-select
            v-model="form.isLockStockAmount"
            placeholder="请选择"
            disabled
          >
            <el-option
              v-for="item in IS_LOCK_STOCK_AMOUNT"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </nh-dialog-form-item>
        <nh-dialog-form-item
          label="是否双人盘点："
          :span="8"
          prop="isDoubleCheck"
        >
          <el-select
            v-model="form.isDoubleCheck"
            placeholder="请选择"
          >
            <el-option
              v-for="item in IS_DOUBLE_CHECK"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </nh-dialog-form-item>
        <!-- 业务不支持暂时屏蔽 2022/4/20 11:30-->
        <!-- <nh-dialog-form-item
          label="是否包含冻结库存："
          :span="8"
          prop="isIncludeFrozenStock"
        >
          <el-select
            v-model="form.isIncludeFrozenStock"
            placeholder="请选择"
          >
            <el-option
              v-for="item in IS_DOUBLE_CHECK"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </nh-dialog-form-item> -->
        <!-- 业务不支持暂时屏蔽 2022/4/22 10:00-->
        <!-- <nh-dialog-form-item
          label="是否过滤占用库位："
          :span="8"
          prop="isFilterLockedLocation"
        >
          <el-select
            v-model="form.isFilterLockedLocation"
            placeholder="请选择"
          >
            <el-option
              v-for="item in IS_DOUBLE_CHECK"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </nh-dialog-form-item> -->
      </div>

      <div style="width: 100%;">
        <div class="title-text-box">
          <nh-title-text title="盘点库位筛选" />
        </div>
        <div v-if="form.countType === ADD_COUNT_TYPE[0].value">
          <nh-dialog-form-item
            label="非空库位抽取比例："
            prop="noEmptyStorehousePercent"
            label-width="140px"
          >
            <el-input
              v-model="form.noEmptyStorehousePercent"
              type="number"
            >
              <template #append>
                %
              </template>
            </el-input>
          </nh-dialog-form-item>
          <nh-dialog-form-item
            label="空库位抽取比例："
            prop="emptyStorehousePercent"
            label-width="140px"
          >
            <el-input
              v-model="form.emptyStorehousePercent"
              type="number"
            >
              <template #append>
                %
              </template>
            </el-input>
          </nh-dialog-form-item>
        </div>
        <div v-if="form.countType === ADD_COUNT_TYPE[1].value">
          <nh-dialog-form-item
            label="动碰次数>=："
            prop="moveTimes"
            :span="8"
            :rules="[
              { validator: verifyInteger, trigger: 'blur' }
            ]"
          >
            <el-input v-model="form.moveTimes" type="number" />
          </nh-dialog-form-item>
          <nh-dialog-form-item
            label="开始时间："
            prop="moveStartTimes"
            :span="8"
          >
            <el-date-picker
              v-model="form.moveStartTimes"
              type="datetime"
              value-format="x"
            />
          </nh-dialog-form-item>
          <nh-dialog-form-item
            label="结束时间："
            prop="moveEndTimes"
            :span="8"
          >
            <el-date-picker
              v-model="form.moveEndTimes"
              type="datetime"
              value-format="x"
            />
          </nh-dialog-form-item>
        </div>
        <el-col :span="7">
          <nh-dialog-form-item
            label="库区："
            :span="24"
            prop="zoneIds"
          >
            <el-checkbox-group v-model="form.zoneIds" class="content-box-left">
              <el-checkbox
                v-for="item in warehouseZonelist"
                :key="item.value"
                :label="item.value"
              >
                {{ item.label }}
              </el-checkbox>
            </el-checkbox-group>
          </nh-dialog-form-item>
        </el-col>
        <el-col
          :span="16"
          :offset="1"
          class="content-box-right"
        >
          <nh-dialog-form-item
            label="起始通道："
            prop="startChannel"
          >
            <el-input v-model="form.startChannel" />
          </nh-dialog-form-item>
          <nh-dialog-form-item
            label="终止通道："
            prop="endChannel"
          >
            <el-input v-model="form.endChannel" />
          </nh-dialog-form-item>
          <nh-dialog-form-item
            label="起始库位："
            prop="startStorehouseCode"
          >
            <el-input v-model="form.startStorehouseCode" />
          </nh-dialog-form-item>
          <nh-dialog-form-item
            label="终止库位："
            prop="endStorehouseCode"
          >
            <el-input v-model="form.endStorehouseCode" />
          </nh-dialog-form-item>
          <nh-dialog-form-item
            label="起始库位列："
            prop="startStorehouseColumn"
          >
            <el-input v-model="form.startStorehouseColumn" />
          </nh-dialog-form-item>
          <nh-dialog-form-item
            label="终止库位列："
            prop="endStorehouseColumn"
          >
            <el-input v-model="form.endStorehouseColumn" />
          </nh-dialog-form-item>
          <nh-dialog-form-item
            label="起始库位层："
            prop="startStorehouseLayer"
          >
            <el-input v-model="form.startStorehouseLayer" />
          </nh-dialog-form-item>
          <nh-dialog-form-item
            label="终止库位层："
            prop="endStorehouseLayer"
          >
            <el-input v-model="form.endStorehouseLayer" />
          </nh-dialog-form-item>
        </el-col>
        <nh-dialog-form-item
          label="货主："
          prop="shipperCode"
          :span="8"
        >
          <el-select
            v-model="form.shipperCode"
            filterable
            clearable
            remote
            :remote-method="handleShippeKeywordrChange"
            :loading="loading.handleShippeKeywordrChange"
            @change="handleChange"
          >
            <el-option
              v-for="item in shipperOptions"
              :key="item.customerCode"
              :label="item.shipperName"
              :value="item.customerCode"
            />
          </el-select>
        </nh-dialog-form-item>
        <nh-dialog-form-item
          label="货品："
          prop="goodsCode"
          :span="8"
        >
          <el-input v-model="form.goodsCode" placeholder="请输入货品编码" />
        </nh-dialog-form-item>
        <nh-dialog-form-item
          label="货品品类："
          prop="goodsTypeCode"
          :span="8"
        >
          <el-cascader
            v-model="form.goodsTypeCode"
            :options="cascader"
            clearable
          />
        </nh-dialog-form-item>
        <nh-dialog-form-item
          label="含税单价>=："
          prop="goodsPrice"
          :span="8"
          :rules="[
            { validator: (rule, value, callback)=>validatorM([0.001, 100000],rule, value, callback),
              trigger: 'blur' }
          ]"
        >
          <el-input v-model="form.goodsPrice" type="number" />
        </nh-dialog-form-item>
        <nh-dialog-form-item
          label="货品质量："
          prop="goodsQuality"
          :span="8"
        >
          <el-select v-model="form.goodsQuality" placeholder="请选择">
            <el-option
              v-for="item in GOODS_QUALITY"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </nh-dialog-form-item>
      </div>

      <div style="width: 100%;">
        <div class="title-text-box">
          <nh-title-text title="任务拆分方式" />
        </div>
        <nh-dialog-form-item
          label="分组："
          :span="8"
          prop="taskGroupType"
        >
          <el-select v-model="form.taskGroupType" placeholder="请选择">
            <el-option
              v-for="item in TASK_GROUP_TYPE"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </nh-dialog-form-item>
        <nh-dialog-form-item
          label="单任务库位数上限："
          :span="8"
          prop="taskNumLimit"
          :rules="[
            { validator: verifyInteger, trigger: 'blur' }
          ]"
        >
          <el-input v-model="form.taskNumLimit" type="number" />
        </nh-dialog-form-item>
      </div>

      <div style="width: 100%;">
        <nh-button
          type="primary"
          @click="handlePreview"
        >
          任务预览
        </nh-button>
        <PlTable
          v-if="isPreview"
          :data="tableData"
          :columns="TABLECOLUMN"
        />
      </div>
    </nh-dialog-form>
    <template #footer>
      <div>
        <nh-button
          type="primary"
          :loading="loading.onSubmitForm"
          @click="onSubmitForm"
        >
          确 定
        </nh-button>
        <nh-button
          @click="onClose"
        >
          取 消
        </nh-button>
      </div>
    </template>
  </nh-dialog>
</template>

<script>
import loadingMixin from '@thales/loading';
import {
  ADD_COUNT_TYPE,
  OPERATION_TYPE,
  IS_SHOW_STOCK_AMOUNT,
  IS_LOCK_STOCK_AMOUNT,
  IS_DOUBLE_CHECK,
  TASK_GROUP_TYPE,
} from '../constant';
import {
  shipperList, getGoodsLevelTree, warehouseZonePage, countOrderGenerate, taskPreview,
} from '../api';

export default {
  name: 'CountOrderAdd',
  mixins: [loadingMixin],
  emits: ['submit'],
  data() {
    return {
      ADD_COUNT_TYPE,
      OPERATION_TYPE,
      IS_SHOW_STOCK_AMOUNT,
      IS_LOCK_STOCK_AMOUNT,
      IS_DOUBLE_CHECK,
      TASK_GROUP_TYPE,
      dialogVisible: false,
      form: {
        zoneIds: [],
        countOperationType: OPERATION_TYPE[1].value,
        isBlindCount: IS_SHOW_STOCK_AMOUNT[0].value,
        isLockStockAmount: IS_LOCK_STOCK_AMOUNT[0].value,
        isDoubleCheck: IS_DOUBLE_CHECK[1].value,
        // isIncludeFrozenStock: IS_DOUBLE_CHECK[1].value, // 默认为否
        // isFilterLockedLocation: IS_DOUBLE_CHECK[1].value, // 默认为否,
        noEmptyStorehousePercent: 100,
        emptyStorehousePercent: 0,
        taskGroupType: TASK_GROUP_TYPE[0].value,
        goodsTypeCode: [],
      },
      shipperOptions: [],
      warehouseZonelist: [],
      cascader: [],
      tableData: [],
      isPreview: false,
      loading: {
        onSubmitForm: false,
      },
      rules: {
        countType: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        countOperationType: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        isBlindCount: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        isLockStockAmount: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        isDoubleCheck: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        isIncludeFrozenStock: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        isFilterLockedLocation: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        taskGroupType: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        taskNumLimit: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
      },
      TABLECOLUMN: [
        {
          label: '任务序号',
          type: 'index',
          width: 100,
        }, {
          label: '包含库位数',
          prop: 'storehouseNum',
          minWidth: 180,
        }, {
          label: '包含货品数',
          prop: 'goodsNum',
          minWidth: 180,
        }, {
          label: '系统库存量(零单位)',
          prop: 'totalAmount',
          minWidth: 180,
        },
      ],
    };
  },
  computed: {
    GOODS_QUALITY() {
      return this.$dictionaryFiledMap.GOODS_QUALITY || [];
    },
  },
  methods: {
    init() {
      this.dialogVisible = true;
      this.handleShippeKeywordrChange();
      this.getCascader();
      this.getSelectList();
    },
    async onSubmitForm() {
      await this.$refs.ruleForm.validate();
      await countOrderGenerate(this.form);
      this.$message({ type: 'success', message: '添加成功！' });
      this.$emit('submit');
      this.onClose();
    },
    async getCascader() {
      const resp = await getGoodsLevelTree({});
      this.cascader = await this.readNodes(resp);
    },
    async getSelectList() {
      const resp = await warehouseZonePage({ page: 1, size: 1000 }, { });
      this.warehouseZonelist = resp.records.map((item) => ({
        label: item.zoneName,
        value: item.id,
      }));
    },
    readNodes(nodes = []) {
      const arr = [];
      nodes.forEach((item, index) => {
        arr.push({ label: item.name, value: item.name });
        if (item.children && item.children.length > 0) {
          arr[index].children = this.readNodes(item.children);
        }
      });
      return arr;
    },
    async handleShippeKeywordrChange(query) {
      this.shipperOptions = await shipperList({ customerNameLike: query });
    },
    handleChange(val) {
      if (!val) {
        this.form.shipperId = '';
      }
      const shipper = this.shipperOptions.find((item) => item.customerCode === val);
      this.form.shipperId = shipper?.id;
    },
    onClose() {
      this.dialogVisible = false;
    },
    handleClose() {
      this.$refs.ruleForm.resetFields();
      this.tableData = [];
    },
    async handlePreview() {
      await this.$refs.ruleForm.validate();
      this.tableData = await taskPreview(this.form);
      this.isPreview = true;
    },
    getLabel(val, arr) {
      const findArr = arr.find((item) => item.value === val);
      return findArr ? findArr.label : '';
    },
    validatorM([min, max], rule, value, callback) {
      if (!value && value !== 0) {
        return callback();
      }
      if (max !== undefined && value > max) {
        return callback(new Error(`请输入小于等于${max}的数`));
      }
      if (min !== undefined && value < min) {
        return callback(new Error(`请输入大于等于${min}的数`));
      }
      return callback();
    },
    verifyInteger(rule, value, callback) {
      const reg = /^[1-9][0-9]*$/;
      const boolean = reg.test(value);
      if (!boolean) {
        return callback(new Error('请输入正整数'));
      }
      return callback();
    },
  },
};
</script>
<style lang="scss" scoped>
.title-text-box {
  width: 100%;
  margin-bottom: 24px;
}

.content-box-left {
  height: 230px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

</style>
<style lang="scss">
.dialog-count-order-add {
  .el-dialog__body {
    overflow: auto;
    height: 60vh;
  }

  .el-date-editor.el-input {
    width: 240px;
  }
}
</style>
