<template>
  <pl-block>
    <PlForm
      v-model="searchQuery"
      mode="search"
      :fields="serchFields"
      @submit="handleQuery"
      @reset="handleQuery"
    />
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.getTableData"
      :data="tableData"
      :columns="TABLECOLUMN"
      :show-table-setting="true"
      @selection-change="handleSelectionChange"
      @update:pagination="handlePaginationChange"
    >
      <template #tableHeaderRight>
        <nh-button
          :track="{
            trackName: '在库管理/盘点管理/[新增]'
          }"
          @click="handleAdd"
        >
          新增
        </nh-button>
      </template>
      <template #countOrderNo="{row}">
        <nh-link
          :to="{}"
          :text="row.countOrderNo"
          @click="jump(row)"
        />
      </template>
      <template #action="{row}">
        <nh-button
          v-if="row.status === COUNT_STATUS_EN.COUNT"
          type="text"
          @click="closeCountOrder(row.id)"
        >
          关闭
        </nh-button>
        <nh-button
          v-if="row.status === COUNT_STATUS_EN.COUNT || row.status === COUNT_STATUS_EN.COUNTING"
          :loading="loading.handleExport"
          type="text"
          @click="handleExport(row.id,row.countOrderNo)"
        >
          导出
        </nh-button>
        <nh-button
          v-if="row.status === COUNT_STATUS_EN.COUNT || row.status === COUNT_STATUS_EN.COUNTING"
          type="text"
          @click="handleImport(row.id)"
        >
          结果导入
        </nh-button>
        <nh-button
          v-if="row.status === COUNT_STATUS_EN.CONFIRMING"
          type="text"
          @click="jump(row,true)"
        >
          结果确认
        </nh-button>
        <nh-button
          type="text"
          @click="handlePrint(row)"
        >
          打印
        </nh-button>
      </template>
    </PlTable>
  </pl-block>
  <count-order-add ref="countOrderAdd" @submit="handleAddNewCount" />
  <nh-data-import
    ref="DataImport"
    :err-msg-list="errMsgList"
    :is-upload="isUpload"
    :data-amount="allCountOrder.length"
    :track="{
      name_zh: `在库管理/盘点管理/弹窗-结果导入`,
      trackName: $route.path
    }"
    @upload="warehouseGoodsImport"
    @downLoadTemplate="handleDownTemplate"
    @submitSuccess="handleGoodsImportSubmitSuccess"
    @cancel="handleGoodsImportCancel"
    @clearAll="handleGoodsImportClearAll"
  />
  <count-manage-details ref="countManage" @onSuccess="getTableData" />
</template>
<script>
import loadingMixin from '@thales/loading';
import { SearchType } from '@/constant/form';
import InLibrary from '@/constant/pageNames/inLibrary';
import {
  TABLECOLUMN, serchFields,
} from './fileds';
import { COUNT_STATUS, COUNT_STATUS_EN } from './constant';
import {
  countOrderPage,
  countOrderClose,
  countOrderDetailExport,
  countOrderImportCheck,
  countOrderImport,
  downLoadTemplate,
} from './api';
import CountOrderAdd from './count-order-add/index.vue';
import CountManageDetails from './count-order-detail/index.vue';

const isExamine = true;
export default {
  name: InLibrary.COUNT_MANAGEMENT,
  components: {
    CountOrderAdd,
    CountManageDetails,
  },
  mixins: [loadingMixin],
  data() {
    return {
      serchFields,
      TABLECOLUMN,
      SearchType,
      isExamine,
      COUNT_STATUS,
      COUNT_STATUS_EN,
      tableData: [],
      searchQuery: {
        createTime: [],
        completeTime: [],
      },
      loading: {
        getTableData: false,
        handleExport: false,
      },
      isEdit: false,
      selectIds: [],
      errMsgList: [],
      allCountOrder: [],
      isUpload: false,
      countOrderId: '',
    };
  },
  activated() {
    this.getTableData();
  },
  methods: {
    formatBatchField(code, batchAttributes) {
      return batchAttributes ? batchAttributes[code] : '';
    },
    handleQuery() {
      this.pagination.page = 1;
      this.getTableData();
    },
    async getTableData() {
      this.selectIds = [];
      const params = this.buildParams();
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const resp = await countOrderPage(pagination, params);
      this.tableData = resp.records.map((item) => {
        const newItem = item;
        if (item.status === COUNT_STATUS_EN.COUNT || item.status === COUNT_STATUS_EN.CLOSE) {
          newItem.diffStorehouseNum = null;
        }
        return newItem;
      });
      this.pagination.total = resp.total;
    },
    handleSelectionChange(selection) {
      this.selectIds = selection.map((item) => item.id);
    },
    handleAddNewCount() {
      if (this.pagination.page === 1) {
        this.getTableData();
      } else {
        this.handleQuery();
      }
    },
    buildParams() {
      const [createTimeStart, createTimeEnd] = this.searchQuery.createTime || [];
      const [completeTimeStart, completeTimeEnd] = this.searchQuery.completeTime || [];
      return {
        ...this.searchQuery,
        createTimeStart,
        createTimeEnd,
        completeTimeStart,
        completeTimeEnd,
      };
    },
    handlePaginationChange() {
      this.getTableData();
    },
    async handleAdd() {
      this.$refs.countOrderAdd.init();
    },
    jump(row, isEdit) {
      this.$refs.countManage.open(row.id, isEdit);
    },
    handlePrint({ id, countType }) {
      const routeUrl = this.$router.resolve({
        path: '/print/count-order-list',
        query: { countOrderId: id, type: countType },
      });
      window.open(routeUrl.href, '_blank');
    },
    async handleExport(id, countOrderNo) {
      await countOrderDetailExport({ id }, countOrderNo);
    },
    async closeCountOrder(id) {
      await this.$confirm('是否确认关闭?', '提示信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      });
      await countOrderClose({ id });
      this.$message({ type: 'success', message: '操作成功！' });
      this.getTableData();
    },
    handleImport(id) {
      this.countOrderId = id;
      this.$refs.DataImport.showDialog();
    },
    async warehouseGoodsImport(fd) {
      const { data, errorMsg = [] } = await countOrderImportCheck(
        fd,
        { countOrderId: this.countOrderId },
      );
      this.errMsgList = [...this.errMsgList, ...errorMsg.map((item) => ({ errorMsg: item }))];
      this.allCountOrder = [...this.allCountOrder, ...data];
      this.isUpload = true;
    },
    async handleDownTemplate() {
      await downLoadTemplate({ templateId: 'count_order_template_id' });
    },
    async handleGoodsImportSubmitSuccess() {
      if (this.errMsgList && this.errMsgList.length <= 0) {
        await this.countOrderSave();
      } else {
        this.$message({
          message: '请根据错误信息修改后再上传',
          type: 'warning',
        });
      }
    },
    async  countOrderSave() {
      const data = {
        countOrderId: this.countOrderId,
        details: [...this.allCountOrder],
      };
      await countOrderImport(data);
      this.$message({
        message: '保存成功',
        type: 'success',
      });
      this.handleGoodsImportClearAll();
      this.$refs.DataImport.handleClose();
      this.getTableData();
    },
    handleGoodsImportCancel() {
      this.handleGoodsImportClearAll();
      this.$refs.DataImport.handleClose();
    },
    handleGoodsImportClearAll() {
      this.isUpload = false;
      this.errMsgList = [];
      this.allCountOrder = [];
    },
  },
};
</script>
<style lang="scss" scoped>
.batch-field {
  text-indent: 12px;
  padding: 4px 0;
}

:deep(.el-date-editor.el-input,
.el-date-editor.el-input__inner) {
  width: 100%;
}
</style>
